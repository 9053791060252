<template>
  <b-row no-gutters>
    <div
        @click="$emit('on:playground:enabled-change')"
        :class="availability ? 'badge-available-success pointer' : 'pointer badge-available-error'"
    >
      <div
          class="badge-text-sm"
      >
        {{ availability ? $t('components.doinsport.table.available') : $t('components.doinsport.table.not-available') }}
      </div>
    </div>
  </b-row>
</template>
<script>
  export default {
    props: {
      availability: {
        type: Boolean,
        default: this
      }
    }
  }
</script>